@keyframes process-map-ring-intro {
  0% {
    transform: scale(1.1);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes process-map-signal-intro {
  0% {
    transform: translateX(-10%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}
@keyframes process-map-tile-intro {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.process-map {
  display: block;
  max-width: 30rem;
  padding: 0 2rem;
  margin: 2rem auto 0;
  position: relative;
  overflow: visible;
}
.process-map__signal,
.process-map__ring,
.process-map__tiles {
  animation-duration: 725ms;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  transform-origin: center center;
}
.process-map__signal {
  animation-name: process-map-signal-intro;
}
.process-map__ring {
  animation-name: process-map-ring-intro;
}
.process-map__tiles {
  animation-name: process-map-tile-intro;
}
.process-map .pm-tiles__obj {
  fill: #8e9dae;
  transition: fill 725ms ease-in;
}
.process-map .pm-tiles--color-low .pm-tiles__obj {
  fill: #045a71;
}
.process-map .pm-tiles--color-medium .pm-tiles__obj {
  fill: #045a71;
}
.process-map .pm-tiles--color-high .pm-tiles__obj {
  fill: #045a71;
}

.cls-1 {
  stroke-width: 6.88px;
}
.cls-1,
.cls-2,
.cls-3,
.cls-4,
.cls-5,
.cls-6,
.cls-7 {
  stroke: #fff;
  stroke-linejoin: round;
}
.cls-1,
.cls-2,
.cls-3,
.cls-4,
.cls-5,
.cls-6,
.cls-7,
.cls-8,
.cls-9,
.cls-10,
.cls-11,
.cls-12,
.cls-13,
.cls-14,
.cls-15 {
  fill-rule: evenodd;
}
.cls-1,
.cls-8 {
  fill: #e67e08;
}
.cls-2 {
  fill: #005b70;
}
.cls-2,
.cls-3,
.cls-4,
.cls-5,
.cls-6,
.cls-7 {
  stroke-width: 9.17px;
}
.cls-3 {
  fill: #0094b5;
}
.cls-4 {
  fill: #00c7f6;
}
.cls-5 {
  fill: #00add6;
}
.cls-6 {
  fill: #004758;
}
.cls-7 {
  fill: #007692;
}
.cls-8,
.cls-16,
.cls-9,
.cls-10,
.cls-11,
.cls-12,
.cls-13,
.cls-14,
.cls-15 {
  stroke-width: 0px;
}
.cls-17 {
  isolation: isolate;
}
.cls-16 {
  fill: #fff;
}
.cls-9 {
  fill: url(#Unbenannter_Verlauf_4);
}
.cls-10 {
  fill: url(#Unbenannter_Verlauf_3);
}
.cls-11 {
  fill: url(#Unbenannter_Verlauf_2);
}
.cls-12 {
  fill: url(#Unbenannter_Verlauf_6);
}
.cls-13 {
  fill: url(#Unbenannter_Verlauf_8);
}
.cls-14 {
  fill: url(#Unbenannter_Verlauf_5);
}
.cls-15 {
  fill: url(#Unbenannter_Verlauf_7);
}
